import { PrismicRichText, SliceZone } from "@prismicio/react"
import { Layout, SEO, sliceComponents } from "components"
import CtaButtonList from "components/CtaButtonList/CtaButtonList"
import BackArrow from "components/ReactSvgIcons/BackArrow"
import FaceBookBgWhite from "components/ReactSvgIcons/faceBookBgWhite"
import LinkedinBgWhite from "components/ReactSvgIcons/LinkedinBgWhite"
import SocialLinkBgWhite from "components/ReactSvgIcons/SocialLinkBgwhite"
import TwitterBgWhite from "components/ReactSvgIcons/twitterBgWhite"
import SmartLink from "components/SmartLink/SmartLink"
import { graphql, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import { formatDate } from "utils/dateFunctions"
import "./details.scss"

interface IBlogPageTemplateProps {
  data: any
}

const BlogPageTemplate: React.FunctionComponent<IBlogPageTemplateProps> = ({
  data,
}) => {
  if (!data) {
    return null
  }

  const doc = data.prismicBlogPage.data
  const val = doc?.body[0]?.primary
  console.log(val, data)
  const handleClick = (e: any) => {
    e.preventDefault()
    if (typeof window !== "undefined") {
      if (window.history.length > 1) {
        navigate(-1)
      } else {
        navigate("/blogs")
      }
    }
  }
  function copyCurrentUrl() {
    navigator.clipboard.writeText(window.location.href).catch(err => {
      console.error("Failed to copy URL: ", err)
    })
  }

  return (
    <Layout>
      <SEO
        title={
          doc.body1?.[0]?.primary?.title || doc.body[0].primary.blog_title.text
        }
        description={doc.body1?.[0]?.primary?.description}
      />
      <div className="BlogDetails">
        <div className="header">
          <div role="button" onClick={handleClick} className="backArrow">
            <BackArrow />
            <div className="text">Back to all posts</div>
          </div>

          <div className="title">{val?.blog_title?.text}</div>
        </div>
        <GatsbyImage
          objectFit="cover"
          className="feature-image"
          alt={val?.feature_image?.alt ?? "Teamble blog feature image"}
          image={val?.feature_image?.gatsbyImageData}
        />
        <div className="content-section">
          <div className="sociallinks">
            <div className="date">{formatDate(doc?.published_at)}</div>
            <div className="links">
              <SmartLink url="https://x.com/TeambleTeam">
                <TwitterBgWhite width={44} height={44} />
              </SmartLink>
              <SmartLink url="https://www.linkedin.com/company/teamble/">
                <LinkedinBgWhite width={44} height={44} />
              </SmartLink>
              <SmartLink url="https://www.facebook.com/TeambleTeam#">
                <FaceBookBgWhite width={44} height={44} />{" "}
              </SmartLink>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={copyCurrentUrl}
              >
                <SocialLinkBgWhite width={44} height={44} />
              </div>
            </div>
          </div>
          <div className="content">
            <PrismicRichText
              field={val?.blog_content?.richText}
              components={{
                strong: (props: any) => (
                  <strong className="strong">{props.children}</strong>
                ),
                heading1: (props: any) => (
                  <h1 className="header-1">{props.children}</h1>
                ),
                heading2: (props: any) => (
                  <h2 className="header-2">{props.children}</h2>
                ),
                paragraph: (props: any) => (
                  <p className="para">{props?.children}</p>
                ),
                list: (props: any) => (
                  <ul className="list">{props.children}</ul>
                ),
                listItem: (props: any) => (
                  <li className="list-items">{props.children}</li>
                ),
                image: ({ node }: any) => (
                  <img
                    src={node.url}
                    alt={node.alt || ""}
                    className="image-card"
                  />
                ),
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CtaButtonList />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyBlogQuery($uid: String) {
    prismicBlogPage(uid: { eq: $uid }) {
      data {
        published_at
        body1 {
          ... on PrismicBlogPageDataBody1GeneralCard {
            slice_type
            primary {
              description
              title
            }
          }
        }
        body {
          ... on PrismicBlogPageDataBodyBlog {
            slice_type
            primary {
              author_description
              author_name
              blog_category
              blog_content {
                richText
              }
              blog_title {
                richText
                text
              }
              feature_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPageTemplate
